import React from 'react';
import { Routes, Route } from 'react-router-dom';

// import Home from '../pages/Home';
import LandingPage from '../pages/LandingPage';
import Privacy from '../pages/Privacy';
import Contact from '../pages/Contact';
import About from '../pages/AboutUs';
// import Login from '../pages/Login';
// import Register from '../pages/Register';
import Support from '../pages/Support';
// import Event from '../pages/Event';
import Terms from '../pages/Terms';


import LandingPageMobile from '../pages/LandingPageMobile';
import PrivacyMobile from '../pages/Mobile/Privacy';
import ContactMobile from '../pages/Mobile/Contact';
import AboutMobile from '../pages/Mobile/AboutUs';
import SupportMobile from '../pages/Mobile/Support';
import TermsMobile from '../pages/Mobile/Terms';
import DeleteAccount from '../pages/DeleteAccount';


const RoutesComponent: React.FC = () => {

  const [isCell, setCell] = React.useState(true);

  function checkDevice() {
    if (navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true; // está utilizando celular
    }

    return false; // não é celular
  }

  React.useEffect(() => {
    if (!checkDevice()) {
      setCell(false)
    }
  }, [])

  if (isCell) {
    return (
      <Routes>
        <Route path="/dashboard" element={<LandingPageMobile/>} />
        <Route path="/" element={<LandingPageMobile/>} />
        <Route path="/support" element={<SupportMobile/>} />
        <Route path="/termos-de-uso" element={<TermsMobile/>} />
        <Route path="/privacy" element={<PrivacyMobile/>} />
        <Route path="/contact" element={<ContactMobile/>} />
        <Route path="/about" element={<AboutMobile/>} />
        <Route path="/delete-account" element={<DeleteAccount/>} />
    </Routes>
    )
  }

  return (
    <Routes>
        <Route path="/dashboard" element={<LandingPage/>} />
        <Route path="/" element={<LandingPage/>} />
        {/* <Route path="/event/:id" element={<Event/>} />
        <Route path="/feedback" element={<FeedBack/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/register" element={<Register/>} />
         */}
         <Route path="/support" element={<Support/>} />
        <Route path="/termos-de-uso" element={<Terms/>} />
        <Route path="/privacy" element={<Privacy/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/delete-account" element={<DeleteAccount/>} />
    </Routes>
  );
};

export default RoutesComponent;
