import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: column;
padding: 40px;
padding-right: 80px;
min-height: 2222px;
background: linear-gradient(315deg, rgba(248, 202, 60, 1) 0%, rgba(252, 13, 117, 1) 27.59%, rgba(27, 27, 26, 1) 90.35%);
`;
export const Banner = styled.img`
position: absolute;
right: 0;
margin-top: 8%;
  height: 150px;
  width: 150px;
`;

export const TextOne = styled.h3`
color:#fff;
margin-left: 7%;
width: 90%;
text-align: center;
`;
export const Title = styled.h1`
margin-left: 3%;
margin-bottom: 3%;
width: 60%;
text-align: center;
margin-top: 37%;
color:#fff;
font-size: 18px;
text-align: center;
font-family: 'Montserrat', sans-serif;
font-weight: 700;
`;
export const Pcomponent = styled.p`
color:#fff;
font-size: 14px;
margin-top: 12%;
text-align: center;
font-family: 'Montserrat', sans-serif;
font-weight: 100;
`;
export const StoreComponent = styled.div`
  display: flex;
  margin-top: 3%;
  margin-left: 4%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 30%;
`;
export const BannerStore = styled.img`
  height: 92px;
  width: 152px;
  object-fit: contain;
`;

export const Banner2 = styled.img`
margin-top: 20%;
  height: 380px;
  width: 190px;
`;
export const Componet2 = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export const Title2 = styled.h1`
margin-left: auto;
margin-right: auto;
width: 100%;
text-align: center;
color:#fff;
font-size: 25px;
text-align: center;
font-family: 'Montserrat', sans-serif;
font-weight: 700;
margin-top: 25%;
`;
export const Pcomponent2 = styled.p`
color:#fff;
margin-top: 15%;
font-size: 15px;
text-align: center;
font-family: 'Montserrat', sans-serif;
font-weight: 300;
`;
export const TitleCenter = styled.h1`
width: 100%;
margin-top: 15%;
margin-bottom: 15%;
text-align: center;
color:#fff;
font-size: 25px;
text-align: center;
font-family: 'Montserrat', sans-serif;
font-weight: 700;
`;
export const Banner3 = styled.img`
margin-top: 4%;
height: 310px;
width: 300px;
margin-left: auto;
margin-right: auto;
`;
export const Pcomponent3 = styled.p`
color:#fff;
margin-top: 4%;
width: 100%;
font-size: 13px;
text-align: center;
font-family: 'Montserrat', sans-serif;
font-weight: 300;
margin-left: auto;
margin-right: auto;
`;
export const CompanyContainer = styled.div`
padding: 40px;
background-color: #2d2d2d;
display: flex;
flex-direction: column;
width: 100%;
border-radius: 20px;
margin-top: 40px;
`;
export const TitleCard = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-between;
width: 50%;
`;
export const TitleCardHead = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
width: 100%;
`;
export const Span = styled.span`
margin-top: 14px;
margin-bottom: 14px;
color:#fff;
font-size: 14px;
font-family: 'Montserrat', sans-serif;
font-weight: 700;
`;

export const UlComponent = styled.ul`
margin:10px;
`;
export const LiComponent = styled.li`
color: #fff;
font-size: 10px;
font-family: 'Montserrat', sans-serif;
font-weight: 100;
margin-bottom: 5px;
`;
export const Banner4 = styled.img`
height: 370px;
width: 160px;
`;
export const ColumnComponent = styled.div`
display: flex;
flex-direction: column;
width: 100%;
`;
export const RowComponent = styled.div`
display: flex;
flex-direction: row;
width: 100%;
margin-top: 10%;
justify-content: space-between;
`;
export const RowComponentRoot = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
width: 100%;
`;


export default Container;