import React, {useState} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import Header  from '../../components/Header';


import api from '../../services/api';
import Container from './styles';


declare const document: any;

const Support = () => {
  // const [isLoad, setIsLoad] = useState(false);
  const [text, setText] = useState('');
  const [email, setEmail] = React.useState('');

  const handleSubmit = async () => {
    
    const deviceIdQuitaBoletos = document?.getElementById("deviceIdQuitaBoletos").value
    const antiFraudIdQuitaBoletos = document?.getElementById("antiFraudIdQuitaBoletos").value
    console.log({
      deviceIdQuitaBoletos,
      antiFraudIdQuitaBoletos,
    })

    if (!email || email.length < 7) {
      alert('Digite um email válido');
      // setIsLoad(false);
      return;
    }
    if (!text || text.length < 19) {
      // setIsLoad(false);
      alert('o Texto deve ter no mínimo 20 caracteres');
      
      return;
    }

    const data = {
      userEmail: email,
      text,

    };
    const {data: response}: any = await api.post('/support', data);
 
    if (response?.message) {
      alert(response.message)
    } else {
      setText('');
      setEmail('');
    }
    // setIsLoad(false);
    
  };

  return (
    <>
    <Header setFilter={() => {}} inputViewSecundary={false}/>
    <Container style={{width: '100%', display:  'flex', justifyContent: 'center', alignItems: 'center', height:'100vh' }}>
      <Box
        component="form"
        sx={{
          border: 'solid 1px #292836',
          padding: 10,
          width:'60%',
          backgroundColor: '#fff',
          display: 'flex',
          flexDirection: 'column'
        }}
        noValidate
        autoComplete="off"
      >
         <InputLabel 
        sx={{
          marginBottom: '20px',
        }}
        htmlFor="input-with-icon-adornment">
          Digite seu Email
        </InputLabel>
        <Input
          onChange={(txt) => setEmail(txt.target.value)}
          value={email}
          id="input-with-icon-adornment"
          startAdornment={
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          }
        />
        <TextField
        sx={{
          marginTop: '20px',
        }}
        id="filled-multiline-flexible"
        label="Como podemos ajudar?"
        multiline
        maxRows={9}
        value={text}
        variant="filled"
        onChange={(txt) => setText(txt.target.value)}
      />
      <div style={{display:  'flex', justifyContent: 'center', alignItems: 'center'}}>
         
              <Button 
              sx={{
                marginTop: 7,
              }}
              onClick={() => {
                handleSubmit()

              }}
              variant="contained">ENVIAR</Button>

            {/* {isLoad  && (
              <Button 
              sx={{
                marginTop: 7,
              }}
              variant="contained" disabled>
                ENVIAR
              </Button>
            )} */}
        </div>
      </Box>
    </Container>
    </>
  );
};
export default Support;
