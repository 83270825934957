import React from 'react';

// import AuthRoutes from './auth.routes';
import LoginRoutes from './routes';

const RoutesComponent: React.FC = () => {


  return (
    <LoginRoutes />
  );
};

export default RoutesComponent;
