import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: column;
padding: 30px;
padding-right: 30px;
min-height: 2222px;
background: linear-gradient(315deg, rgba(248, 202, 60, 1) 0%, rgba(252, 13, 117, 1) 6.59%, rgba(27, 27, 26, 1) 89.35%);
`;


export const Title = styled.h1`
margin-left: 3%;
margin-bottom: 8%;
width: 90%;
text-align: center;
margin-top: 27%;
color:#fff;
font-size: 25px;
text-align: center;
font-family: 'Montserrat', sans-serif;
font-weight: 700;
`;

export const Pcomponent2 = styled.p`
color:#fff;
margin-top: 2%;
font-size: 18px;
text-align: left;
font-family: 'Montserrat', sans-serif;
font-weight: 300;
`;
export const Banner3 = styled.img`
margin-top: 4%;
height: 310px;
width: 300px;
margin-left: auto;
margin-right: auto;
`;
export const Pcomponent3 = styled.p`
color:#fff;
margin-top: 2%;
font-size: 20px;
text-align: left;
font-family: 'Montserrat', sans-serif;
font-weight: 500;
`;
export const CompanyContainer = styled.div`
padding: 40px;
background-color: #2d2d2d;
display: flex;
flex-direction: column;
width: 95%;
border-radius: 20px;
margin-top: 40px;
`;
export const TitleCard = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-between;
width: 50%;
`;
export const TitleCardHead = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
width: 100%;
`;
export const Span = styled.span`
margin-right: 4px;
margin-top: 14px;
margin-bottom: 14px;
color:#fff;
font-size: 17px;
font-family: 'Montserrat', sans-serif;
font-weight: 700;
`;

export const UlComponent = styled.ul`
margin:10px;
`;
export const LiComponent = styled.li`
color: #fff;
font-size: 12px;
font-family: 'Montserrat', sans-serif;
font-weight: 100;
margin-bottom: 5px;
`;
export const Banner4 = styled.img`
height: 420px;
  width: 200px;
margin-left: auto;
margin-right: auto;
`;
export const ColumnComponent = styled.div`
display: flex;
flex-direction: column;
width: 50%;
`;
export const RowComponent = styled.div`
display: flex;
flex-direction: row;
width: 60%;
justify-content: space-between;
`;
export const RowComponentRoot = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
width: 100%;
`;


export default Container;