import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: column;
padding: 40px;
padding-right: 80px;
min-height: 2222px;
background: linear-gradient(315deg, rgba(248, 202, 60, 1) 0%, rgba(252, 13, 117, 1) 27.59%, rgba(27, 27, 26, 1) 90.35%);
`;
export const Banner = styled.img`
position: absolute;
right: 0;
margin-top: 8%;
  height: 442px;
  width: 442px;
`;

export const TextOne = styled.h3`
color:#fff;
margin-left: 7%;
width: 40%;
text-align: center;
`;
export const Title = styled.h1`
margin-left: 3%;
margin-bottom: 3%;
width: 50%;
text-align: center;
margin-top: 17%;
color:#fff;
font-size: 35px;
text-align: center;
font-family: 'Montserrat', sans-serif;
font-weight: 700;
`;
export const Pcomponent = styled.p`
color:#fff;
font-size: 18px;
text-align: center;
font-family: 'Montserrat', sans-serif;
font-weight: 100;
`;
export const StoreComponent = styled.div`
  display: flex;
  margin-top: 3%;
  margin-left: 4%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 30%;
`;
export const BannerStore = styled.img`
  height: 92px;
  width: 152px;
  object-fit: contain;
`;

export const Banner2 = styled.img`
left: 0;
margin-top: 4%;
margin-left: 4%;
  height: 380px;
  width: 190px;
`;
export const Componet2 = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;
export const Title2 = styled.h1`
margin-left: 2%;
min-width: 30%;
text-align: center;
color:#fff;
font-size: 35px;
text-align: center;
font-family: 'Montserrat', sans-serif;
font-weight: 700;
`;
export const Pcomponent2 = styled.p`
color:#fff;
margin-top: 20%;
font-size: 18px;
text-align: center;
font-family: 'Montserrat', sans-serif;
font-weight: 100;
`;
export const TitleCenter = styled.h1`
width: 100%;
margin-top: 6%;
text-align: center;
color:#fff;
font-size: 35px;
text-align: center;
font-family: 'Montserrat', sans-serif;
font-weight: 700;
`;
export const Banner3 = styled.img`
margin-top: 4%;
height: 310px;
width: 300px;
margin-left: auto;
margin-right: auto;
`;
export const Pcomponent3 = styled.p`
color:#fff;
margin-top: 4%;
width: 70%;
font-size: 18px;
text-align: center;
font-family: 'Montserrat', sans-serif;
font-weight: 100;
margin-left: auto;
margin-right: auto;
`;
export const CompanyContainer = styled.div`
padding: 40px;
background-color: #2d2d2d;
display: flex;
flex-direction: column;
width: 95%;
border-radius: 20px;
margin-top: 40px;
`;
export const TitleCard = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-between;
width: 50%;
`;
export const TitleCardHead = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
width: 100%;
`;
export const Span = styled.span`
margin-right: 4px;
margin-top: 14px;
margin-bottom: 14px;
color:#fff;
font-size: 17px;
font-family: 'Montserrat', sans-serif;
font-weight: 700;
`;

export const UlComponent = styled.ul`
margin:10px;
`;
export const LiComponent = styled.li`
color: #fff;
font-size: 12px;
font-family: 'Montserrat', sans-serif;
font-weight: 100;
margin-bottom: 5px;
`;
export const Banner4 = styled.img`
height: 420px;
  width: 200px;
margin-left: auto;
margin-right: auto;
`;
export const ColumnComponent = styled.div`
display: flex;
flex-direction: column;
width: 50%;
`;
export const RowComponent = styled.div`
display: flex;
flex-direction: row;
width: 60%;
justify-content: space-between;
`;
export const RowComponentRoot = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
width: 100%;
`;


export default Container;