import React from 'react';


import Header  from '../../components/Header';



import {
  Container,
  Pcomponent2,
  Title,
} from './styles';



const Home = () => {
  

    return (
      <>
      <Header setFilter={() => {}} inputViewSecundary={false}/>
      <Container>
      <Title>
      SOBRE NÓS
      </Title>
        <Pcomponent2 >Ondehoje é uma startup de tecnologia que
tem como propósito conectar o público e
os promotores de eventos, entregando
soluções completas desde a divulgação da
festa até a compra do ingresso.</Pcomponent2>
<Pcomponent2>
Fazemos isso entregando insights
avançados sobre os eventos e sobre os
perfis dos usuários por localidades por
meio de Business Intelligence (BI).
</Pcomponent2>
 <Pcomponent2>
 ALÉM DO DESIGN E DO FLUXO DE PAGAMENTOS
BEM INTUITIVOS, VAMOS TRABALHAR COM A
MENOR TAXA DO MERCADO PARA COMPRAS NA
PLATAFORMA.

 </Pcomponent2>
 <Pcomponent2>
 EM SINTONIA COM O MERCADO, CONHECEMOS E
CRIAMOS SOLUÇÕES PERSONALIZADAS DE
ACORDO COM A SUA NECESSIDADE.
 </Pcomponent2>
 <Pcomponent2>
 VAMOS AJUDAR AS EMPRESAS A PLANEJAR SEUS
EVENTOS COM BASE EM RELATÓRIOS DOS
USUÁRIOS DE UMA LOCALIDADE ESPECIFICADA
POR ELES.
 </Pcomponent2>

      </Container>
      </>
    );
};
export default Home;
