import React from 'react';

const Terms = () => {

  return (
    <div style={{width: '100%', display:  'flex', justifyContent: 'center', alignItems: 'center', height:'100vh' }}>
      
      <div style={{display:  'flex', justifyContent: 'center', alignItems: 'center'}}>
          <a
          style={{
            color: '#fff',
            fontSize: '27px',
          }}
          href='https://firebasestorage.googleapis.com/v0/b/ondehoje-3d8bb.appspot.com/o/terms-ondehj.pdf?alt=media&token=3ff9ba11-2fc7-4777-936b-2881893bb8a1' download>
            Click para visualizar os Termos de Uso
          </a>
        </div>
    </div>
  );
};
export default Terms;
