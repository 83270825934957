import React from 'react';
// import LoginIcon from '@mui/icons-material/Login';
import { useNavigate } from 'react-router';
import { GiHamburgerMenu } from "react-icons/gi";
import { MdArrowBackIos } from "react-icons/md";



import logo from '../../assets/brand.png';



import {
  Container,
  TopComponent,
  Modal,
  TextOne,
  CloseModal,
} from './styles';

interface IProps {
  setFilter(str: any): void;
  inputViewSecundary: boolean;
  title?: string;
}
const Header = ({setFilter, inputViewSecundary, title}: IProps) => {
  const navigation = useNavigate();
  const [viewModal, setViewModal] = React.useState(false);

  const handleNavigatePrivacy = () => {
    navigation('/privacy');
  }
  
  return (
    <Container>
      <TopComponent>
        <img onClick={() => {navigation('/')}} src={logo} alt='role' />
        <GiHamburgerMenu onClick={() => {setViewModal(!viewModal)}} color='#fff' size={40}/>
               
      </TopComponent>
      {
        viewModal && (
          <Modal>
            <CloseModal>
            <MdArrowBackIos onClick={() => {setViewModal(!viewModal)}} color='#fff'/>
            </CloseModal>

        <TextOne onClick={() => {
          setViewModal(!viewModal)
          navigation('/about')
        }}>Sobre Nós</TextOne>
        <TextOne onClick={() => {
          setViewModal(!viewModal)
          navigation('/support')
        }}>Suporte</TextOne>
        <TextOne onClick={() => {
          setViewModal(!viewModal)
          window.open('https://instagram.com/ondehjapp', '_blank')
        }}>Instagram</TextOne>
        <TextOne onClick={() => {
          setViewModal(!viewModal)
          navigation('/contact')
        }}>Contato</TextOne>
        <TextOne onClick={() => {
          setViewModal(!viewModal)
          handleNavigatePrivacy()
        }}>Privacidade</TextOne>

          </Modal>
        )
      }
    </Container>
  );
};
export default Header;
