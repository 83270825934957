import axios from 'axios';

const timezone = (new Date().getTimezoneOffset() / 60) * -1;
console.log({timezone});

const api = axios.create({
  // baseURL: 'http://localhost:3333',
  baseURL: 'https://api.ondehj.online',
  headers: {
    timezone: String(timezone),
  }
});

export default api;