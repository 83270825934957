import React from 'react';


import Header  from '../../../components/HeaderMobile';



import {
  Container,
  Pcomponent2,
  Title,
} from './styles';



const Home = () => {
  

    return (
      <>
      <Container>
      <Title>
      SOBRE NÓS
      </Title>
        <Pcomponent2 >Ondehoje é uma startup de tecnologia que
tem como propósito conectar o público e
os promotores de eventos, entregando
soluções completas desde a divulgação da
festa até a compra do ingresso.</Pcomponent2>
<Pcomponent2>
Fazemos isso entregando insights
avançados sobre os eventos e sobre os
perfis dos usuários por localidades por
meio de Business Intelligence (BI).
</Pcomponent2>
 <Pcomponent2>
 Além do design e do fluxo de pagamentos 

Bem intuitivos, vamos trabalhar com a 

Menor taxa do mercado para compras na 

Plataforma. 

 </Pcomponent2>
 <Pcomponent2>
 Em sintonia com o mercado, conhecemos e 

Criamos soluções personalizadas de 

Acordo com a sua necessidade. 
 </Pcomponent2>
 <Pcomponent2>
 Vamos ajudar as empresas a planejar seus 

Eventos com base em relatórios dos 

Usuários de uma localidade especificada 

Por eles. 

 
 </Pcomponent2>

      </Container>
      <Header setFilter={() => {}} inputViewSecundary={false}/>
      </>
    );
};
export default Home;
