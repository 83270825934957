import React from 'react';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PasswordOutlined from '@mui/icons-material/SecurityOutlined';
import Button from '@mui/material/Button';
import Header  from '../../components/Header';


import api from '../../services/api';
import Container from './styles';


const DeleteAccount = () => {
  // const [isLoad, setIsLoad] = useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const handleSubmit = async () => {
    try {
      if (!email || email.length < 7) {
        alert('Digite um email válido');
        // setIsLoad(false);
        return;
      }
  
      const data = {
        email,
        password,
  
      };
      const {data: response}: any = await api.post('/user/delete-data', data);
      console.log({
        response,
      })
      if (response?.message) {
        alert(response.message)
      } else {
        setPassword('');
        setEmail('');
        alert('Dados do usuário excluídos com sucesso!')
      }
    } catch (error) {
      alert('email ou senha incorretos')
    }
    
  };

  return (
    <>
    <Header setFilter={() => {}} inputViewSecundary={false}/>
    <Container style={{width: '100%', display:  'flex', justifyContent: 'center', alignItems: 'center', height:'100vh' }}>
      <Box
        component="form"
        sx={{
          border: 'solid 1px #292836',
          padding: 10,
          width:'60%',
          backgroundColor: '#fff',
          display: 'flex',
          flexDirection: 'column'
        }}
        noValidate
        autoComplete="off"
      >
         <InputLabel 
        sx={{
          marginBottom: '20px',
        }}
        htmlFor="input-with-icon-adornment">
          Digite seu Email
        </InputLabel>
        <Input
          onChange={(txt) => setEmail(txt.target.value)}
          value={email}
          id="input-with-icon-adornment"
          startAdornment={
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          }
        />


<InputLabel 
        sx={{
          marginBottom: '20px',
        }}
        htmlFor="input-with-icon-adornment">
          Senha
        </InputLabel>
        <Input
          onChange={(txt) => setPassword(txt.target.value)}
          value={password}
          id="input-with-icon-adornment"
          startAdornment={
            <InputAdornment position="start">
              <PasswordOutlined />
            </InputAdornment>
          }
        />



      <div style={{display:  'flex', justifyContent: 'center', alignItems: 'center'}}>
         
              <Button 
              sx={{
                marginTop: 7,
              }}
              onClick={() => {
                handleSubmit()

              }}
              variant="contained">Deletar minha conta</Button>

            {/* {isLoad  && (
              <Button 
              sx={{
                marginTop: 7,
              }}
              variant="contained" disabled>
                ENVIAR
              </Button>
            )} */}
        </div>
      </Box>
    </Container>
    </>
  );
};
export default DeleteAccount;
