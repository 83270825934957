import styled from 'styled-components';


export const Container = styled.div`
position: fixed;
display: flex;
  top: 0;
  height: 90px;
  width: 100%;
  align-items: center;
  background: linear-gradient(315deg, rgba(248, 202, 60, 1) 0%, rgba(252, 13, 117, 1) 27.59%, rgba(27, 27, 26, 1) 90.35%);
`;
export const TopComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`;
export const RightComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50%;
`;
export const Banner = styled.img`
  height: 107px;
  width: 192px;
  object-fit:contain;
`;
export const TextOne = styled.div`
display: flex;
align-items: center;
padding-right: 18px;
:hover {
  opacity: 0.5;
}
justify-content: center;
text-align: center;
color:#fff;
font-size: 12;
border : 1px solid transparent;
height: 90px;
`;
export const LastTextOne = styled.div`
display: flex;
align-items: center;
padding-right: 18px;
:hover {
  opacity: 0.5;
}
justify-content: center;
text-align: center;
color:#fff;
font-size: 12;
border : 1px solid transparent;
height: 90px;
`;
export const Modal = styled.div`
position: absolute;
display: flex;
top:0;
padding: 12px;
flex-direction: column;
align-items: center;
:hover {
  opacity: 1;
}
width: 70%;
justify-content: center;
text-align: center;
color:#2d2d2d;
font-size: 12;
background-color: #2d2d2d;
border : 1px solid transparent;
`;
export const CloseModal = styled.div`
position: absolute;
display: flex;
top:0;
background-color: #2d2d2d;
margin-left: 100%;
padding: 30px;
border-radius: 12px;
align-items: center;
justify-content: center;
text-align: center;
`;