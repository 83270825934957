import React from 'react';
import { FaPeopleGroup } from "react-icons/fa6";
import { HiMiniBuildingLibrary } from "react-icons/hi2";
import { BiDownArrow } from "react-icons/bi";
import { useSpring, useSpringRef, animated } from '@react-spring/web'



// import Drawer from '../../components/DrawerMirror';
import Header  from '../../components/Header';
import apple from '../../assets/apple.png';
import playstore from '../../assets/playstore2.png';
import banner from '../../assets/bnner.png';
import homePng from '../../assets/home.png';
import homeFilter from '../../assets/home-filter.png';
import cardInsights from '../../assets/cardInsights.png';
import biOne from '../../assets/bi-one.png';
import biTwo from '../../assets/bi-two.png';



import {
  Container,
  Banner,
  Banner2,
  Banner3,
  BannerStore,
  TextOne,
  StoreComponent,
  Pcomponent,
  Pcomponent2,
  Pcomponent3,
  Componet2,
  Title,
  Banner4,
  Title2,
  TitleCenter,
  CompanyContainer,
  TitleCard,
  TitleCardHead,
  Span,
  UlComponent,
  LiComponent,
  RowComponent,
  ColumnComponent,
  RowComponentRoot,
} from './styles';



const Home = () => {
  const [a, setA] = React.useState(0);
  const [b, setB] = React.useState(1);

  const api = useSpringRef()
  // const [state, toggle] = React.useState(true)
  const springs = useSpring({
    ref: api,
    from: { x: 190 },
  })
  const handleClick = () => {
    api.start({
      to: {
        x: springs.x.get() === 0 ? 190 : 0,
      },
    })
  }
  const bannerRef = React.useRef();
  const bannerRef2 = React.useRef();


  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        handleClick()
        setA(0)
        setB(1)
      }
    }
  )

  React.useEffect(() => {
    // document.addEventListener('visibilitychange', handleVisibilityChange);
    if (bannerRef && bannerRef.current) {
      observer.observe(bannerRef.current)
    }
    // Remove the observer as soon as the component is unmounted
    return () => { 
      // observer.disconnect() 
      // observer2.disconnect() 
    }
    // eslint-disable-next-line
  }, [])
  const { x } = useSpring({
    from: { x: a },
    x: b,
    config: { duration: 3000 },
  })

  const springsAsync = useSpring({
    from: { y: -40, x: 0 },
    to: async (next, cancel) => {
      await next({ x: 100 })
      await next({ x: 200 })
      await next({ x: 300 })
      await next({ x: 400 })
      await next({ x: 300 })
      await next({ x: 200 })
      await next({ x: 100 })
      await next({ x: 0 })
    },
    loop: true,
  })
  const springsAsyncReverse = useSpring({
    from: { y: -40, x: 0 },
    to: async (next, cancel) => {
      await next({ x: -100 })
      await next({ x: -200 })
      await next({ x: -300 })
      await next({ x: -400 })
      await next({ x: -300 })
      await next({ x: -200 })
      await next({ x: -100 })
      await next({ x: 0 })
    },
    loop: true,
  })
  

    return (
      <>
      <Header setFilter={() => {}} inputViewSecundary={false}/>
      <Container>
      <animated.div
      ref={bannerRef as any}
      style={{
        ...springs,
      }}
      >
        <Banner src={banner} alt='role' />
      </animated.div>

      
      <Title>
      Tracker de restaurantes, boates, bares e eventos no geral!
      </Title>
      <TextOne>
      <Pcomponent>Com nosso aplicativo você vai ter um filtro com categorias e sub-categorias para encontrar exatamente o que procura, também funciona baseado na sua geolocalização onde você pode definir um raio de até 70 km para suas buscas.</Pcomponent>
      </TextOne>
      <StoreComponent>
      <animated.div
      ref={bannerRef as any}
      style={{
        opacity: x.to({ range: [1, 0], output: [1,0.3] }),
          scale: x.to({
            range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
            output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1],
          }),
      }}
      >
        <BannerStore onClick={() => {
          window.open('https://play.google.com/store/apps/details?id=com.ondehj.app', '_blank')
        }} src={playstore} alt='ondehoje' />
      </animated.div>
      <animated.div
      onClick={() => {
        alert('No momento estamos indisponíveis na aple store, tente nos próximos dias')
      }}
      ref={bannerRef2 as any}
      style={{
        opacity: x.to({ range: [1, 0], output: [1,0.3] }),
          scale: x.to({
            range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
            output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1],
          }),
      }}
      >
        <BannerStore src={apple} alt='ondehj' />    
      </animated.div>
        
      </StoreComponent>

      {/* secao2 */}
      <Componet2>
      <Banner2 src={homePng} alt='ondehoje' />
      <Banner2 src={homeFilter} alt='ondehoje' />
      <Title2>
      Filtro completo com dezenas de categorias!
      </Title2>
        <Pcomponent2 >
      A plataforma permite, por exemplo, que
o usuário use uma configuração com
dezenas de categorias e parametrizando
a distância com um raio de até 70 KM,
nosso algoritmo de geolocalização e
filtros são tagueados mapeamos esse comportamento
pra gerar relatórios de BI.
      </Pcomponent2>
      
      </Componet2>

      {/* secao3 */}

      <TitleCenter>
      Importantes Insights
      </TitleCenter>
      <Banner3 src={cardInsights} alt='ondehoje' />

      {/* secao4 */}

      <TitleCenter>
      NOSSO PLUS
      </TitleCenter>

      <Pcomponent3>
      Trazemos inovação e suprimos
necessidades que existem nesse nicho
de mercado, com soluções criadas que vão revolucionar o turismo e os fim de semanas das pessoas.
      </Pcomponent3>
      <Pcomponent3>
      Vamos ajudar as empresas a planejar seus
eventos com base em relatórios dos
usuários de uma localidade especificada
pela empresa.
      </Pcomponent3>

      {/* secao5 */}
      <RowComponentRoot>
      <ColumnComponent>
      <CompanyContainer>
      <TitleCardHead>
      <TitleCard>
        <HiMiniBuildingLibrary color='#fff'/>
        <Span>
      Para empresas
      </Span>
        </TitleCard>
        <BiDownArrow color='#e0381a'/>
      </TitleCardHead>
      <UlComponent>
        <LiComponent>Vena de INGRESSOS na plataforma;</LiComponent>
        <LiComponent>Insights de views, acessos e curtidas nos eventos;;</LiComponent>
        <LiComponent>Adicione um banner do evento nos destaques;</LiComponent>
        <LiComponent>Parceiros premium terão acessos a dados de categorias mais procuradas em uma localidade específica para inteligência de negócios (bi);</LiComponent>
        <LiComponent>Personalização: poder sugerir melhorias e funcionalidades para nosso laboratório de desenvolvimento.</LiComponent>
      </UlComponent>
      </CompanyContainer>

      <CompanyContainer>
      <TitleCardHead>
      <TitleCard>
      <FaPeopleGroup color='#fff'/>
        <Span>
      PARA PESSOAS
      </Span>
      
        </TitleCard>
        <BiDownArrow color='#fff'/>
      </TitleCardHead>
      <UlComponent>
        <LiComponent>Busca facilitada de diversos eventos com ampla categorização e filtros;</LiComponent>
        <LiComponent>Compra de ingressos dentro da plataforma com maior segurança;</LiComponent>
        <LiComponent>Favoritar eventos e compartilhar nas redes sociais. </LiComponent>
        <LiComponent>Turismo: Você poderá explorar estabelecimentos e eventos de uma localidade antes mesmo de viajar até lá;</LiComponent>
      </UlComponent>
      </CompanyContainer>
      </ColumnComponent>

      <RowComponent>
      <animated.div
      style={{
        ...springsAsync,
      }}
    >
<Banner4 src={biOne} alt='ondehoje' />
    </animated.div>

    <animated.div
      style={{
        ...springsAsyncReverse,
      }}>
      <Banner4 src={biTwo} alt='ondehoje' />
    </animated.div>
        
        
      </RowComponent>

      </RowComponentRoot>


      {/* <Drawer /> */}
      </Container>
      </>
    );
};
export default Home;
