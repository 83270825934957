import React from 'react';
// import LoginIcon from '@mui/icons-material/Login';
import { useNavigate } from 'react-router';

import logo from '../../assets/brand.png';



import {
  // Avatar,
  RightComponent,
  Container,
  TopComponent,
  TextOne,
  LastTextOne,
} from './styles';

interface IProps {
  setFilter(str: any): void;
  inputViewSecundary: boolean;
  title?: string;
}
const Header = ({setFilter, inputViewSecundary, title}: IProps) => {
  const navigation = useNavigate();
  const [isCell, setCell] = React.useState(true);
  function checkDevice() {
    if (navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true; // está utilizando celular
    }

    return false; // não é celular
  }

  React.useEffect(() => {
    if (!checkDevice()) {
      setCell(false)
    }
  }, [])

  const handleNavigatePrivacy = () => {
    navigation('/privacy');
  }
  
  return (
    <Container>
      <TopComponent>
        <img onClick={() => {navigation('/')}} src={logo} alt='role' />
        <RightComponent>
        <TextOne onClick={() => {navigation('/about')}}>Sobre Nós</TextOne>
        <TextOne onClick={() => {navigation('/support')}}>Suporte</TextOne>
        <TextOne onClick={() => {window.open('https://instagram.com/ondehjapp', '_blank')}}>Instagram</TextOne>
        <TextOne onClick={() => {navigation('/contact')}}>Contato</TextOne>
        <LastTextOne onClick={() => {handleNavigatePrivacy()}}>Privacidade</LastTextOne>
        </RightComponent>
             
      </TopComponent>
      <div>
        {
          isCell && (
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: 'auto', marginRight: 'auto', width: '90%', marginTop: '17px'}}>
            {/* <InputComponent>
              <Input 
              onChange={(txt) => setFilter(txt.target.value)}
              placeholder="Buscar evento"/>
              <img src={lupa} alt='festas'/>
            </InputComponent> */}
            <div />
            
            </div>
            
          )
        }
        
      </div>
    </Container>
  );
};
export default Header;
