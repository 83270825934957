import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;600&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;600&family=Space+Grotesk:wght@400;500;600&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&family=Space+Grotesk&display=swap');
:root {
  --primary100: #F0F1F5;
  --primary200: #A6ACC1;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  font-family: 'Montserrat', sans-serif;
}

a {
  text-decoration: none;
}

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  /* font-family: 'Inter', sans-serif; */
  font-family: 'Poppins', sans-serif;
  background: #1F1F1F;
  -webkit-font-smoothing: antialiased;
}


`;
