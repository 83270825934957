import React from 'react';


import Header  from '../../components/Header';



import {
  Container,
  Pcomponent2,
  Pcomponent3,
  Title,
} from './styles';



const Home = () => {
  

    return (
      <>
      <Header setFilter={() => {}} inputViewSecundary={false}/>
      <Container>
      <Title>
      POLÍTICA DE PRIVACIDADE
      </Title>
        <Pcomponent2 >Este aplicativo é mantido e operado por Ondehj</Pcomponent2>
 

      <Pcomponent2>
      Nós coletamos e utilizamos alguns dados pessoais que pertencem àqueles que utilizam nosso
aplicativo. Ao fazê-lo, agimos na qualidade de controlador desses dados e estamos sujeitos às
disposições da Lei Federal n. 13.709/2018 (Lei Geral de Proteção de Dados Pessoais - LGPD).
      </Pcomponent2>
      <Pcomponent2>
      Nós cuidamos da proteção de seus dados pessoais e, por isso, disponibilizamos esta política de
privacidade, que contém informações importantes sobre:
      </Pcomponent2>
      <Pcomponent2>
      - Quem deve utilizar nosso aplicativo;<br/>
- Quais dados coletamos e o que fazemos com eles;<br/>
- Seus direitos em relação aos seus dados pessoais; e<br/>
- Como entrar em contato conosco.
      </Pcomponent2>
      <Pcomponent3>
      1. Quem deve utilizar nosso aplicativo
      </Pcomponent3>
      <Pcomponent2>
      Nosso aplicativo somente deve ser utilizado por pessoas que tenham, pelo menos, 14 (quatorze)
anos de idade, sendo que a utilização por pessoa com menos de 18 (dezoito) anos somente será
possível mediante o consentimento de pelo menos um de seus pais ou responsável.
      </Pcomponent2>


      <Pcomponent3>2. Dados que coletamos e motivos da coleta</Pcomponent3>
      <Pcomponent2>
      Nosso aplicativo coleta e utiliza alguns dados pessoais de nossos usuários, de acordo com o disposto
nesta seção.<br/><br/>
1. Dados pessoais fornecidos expressamente pelo usuário
Nós coletamos os seguintes dados pessoais que nossos usuários nos fornecem expressamente ao
utilizar nosso aplicativo:
CNPJ, CPF, endereço de e-mail e número de telefone
A coleta destes dados ocorre nos seguintes momentos:
Quando o usuário cria uma conta
Os dados fornecidos por nossos usuários são coletados com as seguintes finalidades:
Para validação e verificação de pessoas e empresas reais, segurança da conta e tratamentos de
recuperação de acesso.<br/><br/>
2. Dados pessoais obtidos de outras formas
Nós coletamos os seguintes dados pessoais de nossos usuários:
Dados de geolocalização
A coleta destes dados ocorre nos seguintes momentos:
Na inicialização do aplicativo
Estes dados são coletados com as seguintes finalidades:
Garantir um feed de eventos mais próximos da localização atual do usuário.<br/><br/>
3. Dados sensíveis
Não serão coletados dados sensíveis de nossos usuários, assim entendidos aqueles definidos nos
arts. 11 e seguintes da Lei de Proteção de Dados Pessoais. Assim, não haverá coleta de dados sobre
origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de
caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou
biométrico, quando vinculado a uma pessoa natural.<br/><br/>
4. Coleta de dados não previstos expressamente
Eventualmente, outros tipos de dados não previstos expressamente nesta Política de Privacidade
poderão ser coletados, desde que sejam fornecidos com o consentimento do usuário, ou, ainda, que
a coleta seja permitida com fundamento em outra base legal prevista em lei.
Em qualquer caso, a coleta de dados e as atividades de tratamento dela decorrentes serão
informadas aos usuários do aplicativo.
      </Pcomponent2>

      <Pcomponent3>3. Compartilhamento de dados pessoais com terceiros</Pcomponent3>
      <Pcomponent2>Nós não compartilhamos seus dados pessoais com terceiros. Apesar disso, é possível que o façamos
para cumprir alguma determinação legal ou regulatória, ou, ainda, para cumprir alguma ordem
expedida por autoridade pública.</Pcomponent2>

      <Pcomponent3>4. Por quanto tempo seus dados pessoais serão armazenados</Pcomponent3>
      <Pcomponent2>Os dados pessoais coletados pelo aplicativo são armazenados e utilizados por período de tempo que
corresponda ao necessário para atingir as finalidades elencadas neste documento e que considere os
direitos de seus titulares, os direitos do controlador do aplicativo e as disposições legais ou
regulatórias aplicáveis.<br/><br/>
Uma vez expirados os períodos de armazenamento dos dados pessoais, eles são removidos de
nossas bases de dados ou anonimizados, salvo nos casos em que houver a possibilidade ou a
necessidade de armazenamento em virtude de disposição legal ou regulatória.</Pcomponent2>

      <Pcomponent3>5. Bases legais para o tratamento de dados pessoais</Pcomponent3>
      <Pcomponent2>Cada operação de tratamento de dados pessoais precisa ter um fundamento jurídico, ou seja, uma
base legal, que nada mais é que uma justificativa que a autorize, prevista na Lei Geral de Proteção de
Dados Pessoais.<br/><br/>
Todas as Nossas atividades de tratamento de dados pessoais possuem uma base legal que as
fundamenta, dentre as permitidas pela legislação. Mais informações sobre as bases legais que
utilizamos para operações de tratamento de dados pessoais específicas podem ser obtidas a partir
de nossos canais de contato, informados ao final desta Política.</Pcomponent2>

      <Pcomponent3>6. Como o titular pode exercer seus direitos</Pcomponent3>
      <Pcomponent2>Para garantir que o usuário que pretende exercer seus direitos é, de fato, o titular dos dados
pessoais objeto da requisição, poderemos solicitar documentos ou outras informações que possam
auxiliar em sua correta identificação, a fim de resguardar nossos direitos e os direitos de terceiros.
Isto somente será feito, porém, se for absolutamente necessário, e o requerente receberá todas as
informações relacionadas.</Pcomponent2>

      <Pcomponent3>7. Medidas de segurança no tratamento de dados pessoais</Pcomponent3>
      <Pcomponent2>Empregamos medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não
autorizados e de situações de destruição, perda, extravio ou alteração desses dados.<br/>
As medidas que utilizamos levam em consideração a natureza dos dados, o contexto e a finalidade
do tratamento, os riscos que uma eventual violação geraria para os direitos e liberdades do usuário,
e os padrões atualmente empregados no mercado por empresas semelhantes à nossa.<br/>
Entre as medidas de segurança adotadas por nós, destacamos as seguintes:<br/><br/>
Armazenamento de senhas utilizando criptografia<br/><br/>
Restrições de acessos externos aos bancos de dados<br/><br/>
Rotas da Api com tokens de segurança<br/><br/>
Ainda que adote tudo o que está ao seu alcance para evitar incidentes de segurança, é possível que
ocorra algum problema motivado exclusivamente por um terceiro - como em caso de ataques de
hackers ou crackers ou, ainda, em caso de culpa exclusiva do usuário, que ocorre, por exemplo,
quando ele mesmo transfere seus dados a terceiro. Assim, embora sejamos, em geral, responsáveis
pelos dados pessoais que tratamos, nos eximimos de responsabilidade caso ocorra uma situação
excepcional como essas, sobre as quais não temos nenhum tipo de controle.<br/>
De qualquer forma, caso ocorra qualquer tipo de incidente de segurança que possa gerar risco ou
dano relevante para qualquer de nossos usuários, comunicaremos os afetados e a Autoridade
Nacional de Proteção de Dados acerca do ocorrido, em conformidade com o disposto na Lei Geral de
Proteção de Dados.<br/>
</Pcomponent2>

      <Pcomponent3>8. Alterações nesta política</Pcomponent3>
      <Pcomponent2>A presente versão desta Política de Privacidade foi atualizada pela última vez em: 22/11/2023<br/>
Reservamo-nos o direito de modificar, a qualquer momento, as presentes normas, especialmente
para adaptá-las às eventuais alterações feitas em nosso aplicativo, seja pela disponibilização de
novas funcionalidades, seja pela supressão ou modificação daquelas já existentes.<br/>
Sempre que houver uma modificação, nossos usuários serão notificados acerca da mudança.</Pcomponent2>

      <Pcomponent3>10. Como entrar em contato conosco</Pcomponent3>
      <Pcomponent2>Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que
tratamos, entre em contato com nosso Encarregado de Proteção de Dados Pessoais, por algum dos
canais mencionados abaixo:<br/><br/>
E-mail: ondehj.dev@gmail.com<br/><br/>
Telefone: 96 98104-3834<br/><br/>
Endereço postal: rua engenheiro niepce da silva, 290 ap 74 bloco C<br/><br/>
</Pcomponent2>


      </Container>
      </>
    );
};
export default Home;
